<template>
  <div v-if="respData">
    <b-row v-if="$ability.can('read', this.$route.meta.resource)" class="match-height">
      <b-col md="12">
        <b-card :title="'System : ' + respData['applicationId']['name']">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Dev :
            </b-card-text>
            <b-form-checkbox :checked="respData['dev'] != null ? respData['dev'] : false" class="custom-control-primary"
              name="check-button" switch @change="switchChange('dev', $event)" />
            <b-card-text class="ml-2">
              {{
                respData["dev"] != null
                  ? respData["dev"] == true
                    ? "เปิดระบบพัฒนา"
                    : "ปิดระบบพัฒนา"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Maintenance :
            </b-card-text>
            <b-form-checkbox :checked="respData['maintenance'] != null
              ? respData['maintenance']
              : false
              " class="custom-control-primary" name="check-button" switch
              @change="switchChange('maintenance', $event)" />
            <b-card-text class="ml-2">
              {{
                respData["maintenance"] != null
                  ? respData["maintenance"] == true
                    ? "เปิดการปรับปรุงระบบ"
                    : "ปิดการปรับปรุงระบบ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="File">
          <b-row>
            <b-col cols="4">
              <p class="mb-1">
                Android : {{ file.android.fcm.name }}
                <b-badge pill :variant="file.android.fcm.name !== '' ? 'success' : 'dark'">
                  {{ file.android.fcm.name !== '' ? $t('Uploaded') : $t('Not uploaded yet') }}
                </b-badge>
              </p>
              <p class="mb-1">
                {{ $t('Update') }} : {{ file.android.fcm.updated !== null ? file.android.fcm.updated : '' |
                  formatDateTime }}
              </p>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                @click="$refs.refUploadAndroid.$el.click()">
                {{ $t("Upload") }}
              </b-button>
              <b-form-file ref="refUploadAndroid" accept=".json" :hidden="true" plain
                @change="inputImageRenderer($event, 'android')" />
            </b-col>
            <b-col cols="4">
              <p class="mb-1">
                iOS : {{ file.ios.fcm.name }}
                <b-badge pill :variant="file.ios.fcm.name !== '' ? 'success' : 'dark'">
                  {{ file.ios.fcm.name !== '' ? $t('Uploaded') : $t('Not uploaded yet') }}
                </b-badge>
              </p>
              <p class="mb-1">
                {{ $t('Update') }} : {{ file.ios.fcm.updated !== null ? file.ios.fcm.updated : '' | formatDateTime }}
              </p>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                @click="$refs.refUploadiOS.$el.click()">
                {{ $t("Upload") }}
              </b-button>
              <b-form-file ref="refUploadiOS" accept=".plist" :hidden="true" plain
                @change="inputImageRenderer($event, 'ios')" />
            </b-col>
            <b-col cols="4">
              <p class="mb-1">
                Huawei : {{ file.huawei.fcm.name }}
                <b-badge pill :variant="file.huawei.fcm.name !== '' ? 'success' : 'dark'">
                  {{ file.huawei.fcm.name !== '' ? $t('Uploaded') : $t('Not uploaded yet') }}
                </b-badge>
              </p>
              <p class="mb-1">
                {{ $t('Update') }} : {{ file.huawei.fcm.updated !== null ? file.huawei.fcm.updated : '' | formatDateTime
                }}
              </p>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                @click="$refs.refUploadHuawei.$el.click()">
                {{ $t("Upload") }}
              </b-button>
              <b-form-file ref="refUploadHuawei" accept=".json" :hidden="true" plain
                @change="inputImageRenderer($event, 'huawei')" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="File Google">
          <b-row>
            <b-col cols="4">
              <p class="mb-1">
                File Json Google
                <b-badge pill :variant="notification.key_json.key !== '' ? 'success' : 'dark'">
                  {{ notification.key_json.key !== '' ? $t('Uploaded') : $t('Not uploaded yet') }}
                </b-badge>
              </p>
              <p class="mb-1">
                {{ $t('Update') }} : {{ notification.key_json.key !== null ? notification.key_json.updated : '' |
                  formatDateTime }}
              </p>

              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" class="mb-75 mr-75"
                @click="$refs.refUploadKeyJson.$el.click()">
                {{ $t("Upload") }}
              </b-button>
              <b-form-file ref="refUploadKeyJson" accept=".json" :hidden="true" plain
                @change="readJsonAndUpdate($event, 'key_json')" />
            </b-col>
            <b-col cols="8">
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="For Build Application">
          <b-row>
            <b-col cols="12">
              <b-form-group label-cols="2" label-cols-lg="2" label="MappingAPI :" label-for="input-lg-mappingAPI">
                <b-form-input id="input-lg-mappingAPI" v-model="mappingAPI" placeholder="mappingAPI" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-cols="2" label-cols-lg="2" label="AppBundle :" label-for="input-lg-appBundle">
                <b-form-input id="input-lg-appBundle" v-model="appBundle" placeholder="appBundle"
                  @input="typingAppbundle($event)" />
                <small v-if="!canUseAppbundle" class="text-danger">{{ $t('This appbundle cannot be used') }}</small>

              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-cols="2" label-cols-lg="2" label="FacebookId :" label-for="input-lg-facebookId">
                <b-form-input id="input-lg-facebookId" v-model="facebookId" placeholder="facebookId" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-cols="2" label-cols-lg="2" label="Ads :" label-for="input-lg-ads">
                <b-form-input id="input-lg-ads" v-model="ads" placeholder="ads" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button size="sm" variant="primary" @click="inputChange('forBuildApplication')">
                <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
                <feather-icon icon="SaveIcon" size="12" />
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <!-- Omise -->
        <b-card title="Omise">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Status :
            </b-card-text>
            <b-form-checkbox :checked="respData['omise'] != null
              ? respData['omise']['status']
              : false
              " class="custom-control-primary" name="check-button" switch
              @change="switchChange('omise.status', $event)" />
            <b-card-text class="ml-2">
              {{
                respData["omise"] != null
                  ? respData["omise"]["status"] == true
                    ? "เปิด (Omise)"
                    : "ปิด (Omise)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>

          </b-row>
          <div v-if="respData['omise']['status']">
            <b-form-group label-cols="2" label-cols-lg="2" label="Public Key :" label-for="input-lg-omise-public-key">
              <b-form-input id="input-lg-omise-public-key" v-model="omisePublicKey" placeholder="omisePublicKey" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="Secret Key :" label-for="input-lg-omise-secret-key">
              <b-form-input id="input-lg-omise-secret-key" v-model="omiseSecretKey" placeholder="omiseSecretKey" />
            </b-form-group>
            <b-button size="sm" variant="primary" @click="inputChange('omise')">
              <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
              <feather-icon icon="SaveIcon" size="12" />
            </b-button>
            <b-row class="mt-1">
              <b-card-text class="ml-2 mr-2">
                Activate :
              </b-card-text>
              <b-card-text class="ml-2">
                <b-badge :variant="respData['omise'] != null
                  ? respData['omise']['activate'] == true
                    ? 'success'
                    : 'info'
                  : 'dark'">
                  {{
                    respData["omise"]
                      != null
                      ?
                      respData['omise']['activate'] ==
                        true
                        ?
                        "ยืนยันแล้ว"
                        :
                        "ยังไม่ยืนยัน"
                      :
                      "ยังไม่ได้ตั้งค่า"
                  }}
                </b-badge>
                <b-button class="ml-2" size="sm" variant="primary">
                  <span class="mr-25 align-middle"> {{ $t("Activate") }}</span>
                  <feather-icon icon="CheckIcon" size="12" />
                </b-button>
              </b-card-text>

            </b-row>

          </div>

        </b-card>
        <!-- Omise -->
        <!-- Pay Solutions -->
        <b-card title="Pay Solutions">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Status :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment']['paysolutions'] != null
              ? respData['payment']['paysolutions']['status']
              : false
              " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.paysolutions.status', $event)" />
            <b-card-text class="ml-2">
              {{
                respData['payment']['paysolutions'] != null
                  ? respData['payment']['paysolutions']["status"] == true
                    ? "เปิด (Pay Solutions)"
                    : "ปิด (Pay Solutions)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>

          </b-row>
          <div v-if="respData['payment']['paysolutions']['status']">
            <b-form-group label-cols="2" label-cols-lg="2" label="Merchant Id :"
              label-for="input-lg-paysolutions-merchant">
              <b-form-input id="input-lg-paysolutions-merchant" v-model="paysolutionsMerchantId"
                placeholder="Merchant Id" />
            </b-form-group>

            <b-form-group label-cols="2" label-cols-lg="2" label="Secret Key :"
              label-for="input-lg-paysolutions-secret-key">
              <b-form-input id="input-lg-paysolutions-secret-key" v-model="paysolutionsSecretKey"
                placeholder="Secret Key" />
            </b-form-group>

            <b-form-group label-cols="2" label-cols-lg="2" label="API Key :" label-for="input-lg-paysolutions-api-key">
              <b-form-input id="input-lg-paysolutions-api-key" v-model="paysolutionsApiKey" placeholder="Api Key" />
            </b-form-group>

            <b-form-group label-cols="2" label-cols-lg="2" label="Auth Key :"
              label-for="input-lg-paysolutions-auth-key">
              <b-form-input id="input-lg-paysolutions-auth-key" v-model="paysolutionsAuthKey" placeholder="Auth Key" />
            </b-form-group>

            <b-button size="sm" variant="primary" @click="inputChange('paysolutions')">
              <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
              <feather-icon icon="SaveIcon" size="12" />
            </b-button>

            <!-- <b-row class="mt-1">
              <b-card-text class="ml-2 mr-2">
                Activate :
              </b-card-text>
              <b-card-text class="ml-2">
                <b-badge
                  :variant="respData['omise'] != null
                    ? respData['omise']['activate'] == true
                      ? 'success'
                      : 'info'
                    :'dark'"
                >
                  {{
                    respData["omise"]
                      !=null
                      ?
                        respData['omise']['activate']==
                        true
                          ?
                            "ยืนยันแล้ว"
                          :
                            "ยังไม่ยืนยัน"
                      :
                        "ยังไม่ได้ตั้งค่า"
                  }}
                </b-badge>
                <b-button
                  class="ml-2"
                  size="sm"
                  variant="primary"
                >
                  <span class="mr-25 align-middle"> {{ $t("Activate") }}</span>
                  <feather-icon
                    icon="CheckIcon"
                    size="12"
                  />
                </b-button>
              </b-card-text>

            </b-row> -->

          </div>

        </b-card>
        <!-- Pay Solutions -->
      </b-col>
      <b-col v-if="respData['omise']['status']" md="6">
        <b-card title="Omise Setting">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Promptpay :
            </b-card-text>
            <b-form-checkbox :checked="respData['omise'] != null
              ? respData['omise']['promptpay']
              : false
              " class="custom-control-primary" name="check-button" switch
              @change="switchChange('omise.promptpay', $event)" />
            <b-card-text class="ml-2">
              {{
                respData["omise"] != null
                  ? respData["omise"]["promptpay"] == true
                    ? "เปิด (Promptpay)"
                    : "ปิด (Promptpay)"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>

          </b-row>

        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Payment">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Wallet :
            </b-card-text>
            <b-form-checkbox :checked="respData['payment'] != null
              ? respData['payment']['wallet']['status']
              : false
              " class="custom-control-primary" name="check-button" switch
              @change="switchChange('payment.wallet.status', $event)" />
            <b-card-text class="ml-2">
              {{
                respData["payment"] != null
                  ? respData["payment"]["wallet"]["status"] == true
                    ? "เปิดระบบกระเป๋าเงิน"
                    : "ปิดระบบกระเป๋าเงิน"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="autoMessageContactUs">
          <b-form-group label-cols="2" label-cols-lg="2" label="Message :" label-for="input-lg-autoMessageContactUs">
            <b-form-input id="input-lg-autoMessageContactUs" v-model="autoMessageContactUs"
              placeholder="autoMessageContactUs" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('autoMessageContactUs')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Notification">
          <b-form-group label-cols="2" label-cols-lg="2" label="Key :" label-for="input-lg-notification-key">
            <b-form-input id="input-lg-notification-key" v-model="notificationKey" placeholder="Key" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Serverkey :"
            label-for="input-lg-notification-serverkey">
            <b-form-input id="input-lg-notification-serverkey" v-model="notificationServerkey"
              placeholder="Serverkey" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('notification')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="otpSetting">
          <b-form-group label-cols="2" label-cols-lg="2" label="Type :" label-for="input-lg-type">
            <v-select id="input-lg-type" v-model="otpSetting.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :options="OptionTypeOTP" :reduce="(OptionTypeOTP) => OptionTypeOTP.value"
              class="select-size-lg" />
          </b-form-group>
          <div v-if="otpSetting.type === 'thaibulksms'">
            <b-form-group label-cols="2" label-cols-lg="2" label="Username :" label-for="input-lg-username">
              <b-form-input id="input-lg-username" v-model="otpSetting.username" placeholder="Username" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="Password :" label-for="input-lg-password">
              <b-form-input id="input-lg-password" v-model="otpSetting.password" placeholder="Password" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="ExpireDate :" label-for="input-lg-expireDate">
              <b-form-input id="input-lg-expireDate" v-model="otpSetting.expireDate" placeholder="ExpireDate" />
            </b-form-group>
            <b-form-group label-cols="2" label-cols-lg="2" label="smsAmount :" label-for="input-lg-smsAmount">
              <b-form-input id="input-lg-smsAmount" v-model="otpSetting.smsAmount" placeholder="smsAmount" />
            </b-form-group>
            <b-form-tags v-model="otpSetting.message" no-outer-focus>
              <template #default="{
                tags,
                inputAttrs,
                inputHandlers,
                addTag,
                removeTag,
              }">
                <b-card-text class="mr-2">
                  Message :
                </b-card-text>
                <span v-for="(otp, index) in otpSetting.message" :key="index">{{
                  otp
                }}</span>
                <br>
                <b-row>
                  <b-button size="sm" variant="primary" class="ml-2 mr-2">
                    <span class="mr-25 align-middle"> OTP </span>
                  </b-button>
                  <b-button size="sm" variant="primary" class="mr-2">
                    <span class="mr-25 align-middle"> REF </span>
                  </b-button>
                  <b-button size="sm" variant="primary" class="mr-2">
                    <span class="mr-25 align-middle"> MINUTE </span>
                  </b-button>
                </b-row>

                <b-input-group aria-controls="my-custom-tags-list">
                  <input v-bind="inputAttrs" placeholder="เพิ่มข้อความใหม่" class="form-control" v-on="inputHandlers">
                  <b-input-group-append>
                    <b-button variant="primary" @click="addTag()">
                      {{ $t("Add") }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-col cols="12">
                  <draggable v-model="otpSetting.message" tag="ul" class="list-group cursor-move">
                    <b-list-group-item v-for="(tag, index) in tags" :key="index" tag="li">
                      <div class="d-flex">
                        <!-- <b-avatar :text="listItem.name.slice(0,2)" /> -->
                        <div class="ml-25">
                          <b-card-text class="mb-0">
                            {{ tag }}
                          </b-card-text>
                          <b-button variant="link" size="sm" :aria-controls="`my-custom-tags-tag_${tag.replace(
                            /\s/g,
                            '_'
                          )}_`" class="py-0" @click="removeTag(tag)">
                            {{ $t("Delete") }}
                          </b-button>
                        </div>
                      </div>
                    </b-list-group-item>
                    <!-- <b-row
                    v-for="(tag,index) in tags"
                    :key="index"
                  >
                    <b-card
                      :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                      tag="li"
                      class="shadow-none border mt-1 mr-1 ml-1 mb-0"
                    >
                      <strong>{{ "[ "+ (index+1) + " ]" }} {{ tag }}</strong>
                      <b-button
                        variant="link"
                        size="sm"
                        :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        class="py-0"
                        @click="removeTag(tag)"
                      >
                        {{ $t('Delete') }}
                      </b-button>
                    </b-card>
                  </b-row> -->
                  </draggable>
                </b-col>
              </template>
            </b-form-tags>
          </div>
          <b-button size="sm" variant="primary" @click="inputChange('otpSetting')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Line">
          <b-row class="mt-1">
            <b-card-text class="ml-1">
              Verify :
            </b-card-text>
            <b-card-text class="ml-2">
              {{
                respData["line"] != null
                  ? respData["line"]["verify"] == true
                    ? "ยืนยันแล้ว"
                    : "ยังไม่ได้รับการยืนยัน"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-form-group label-cols="2" label-cols-lg="2" label="Key :" label-for="input-lg-line-key">
            <b-form-input id="input-lg-line-key" v-model="lineKey" placeholder="Key" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('line')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="FontFamily">
          <b-form-group label-cols="2" label-cols-lg="2" label="Type :" label-for="input-lg-type">
            <v-select id="input-lg-type" v-model="fontFamily" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name" :options="OptionFontFamily" :reduce="(OptionFontFamily) => OptionFontFamily.value"
              class="select-size-lg" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('fontFamily')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card title="Mobile Color">
          <b-form-group label-cols="2" label-cols-lg="2" label="Primary Color :" label-for="input-lg-primaryColor">
            <b-form-input id="input-lg-primaryColor" v-model="primaryColor" placeholder="Primary Color" type="color" />
            <b-form-input id="input-lg-primaryColor" v-model="primaryColor" placeholder="Primary Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Secondary Color :" label-for="input-lg-secondaryColor">
            <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color"
              type="color" />
            <b-form-input id="input-lg-secondaryColor" v-model="secondaryColor" placeholder="Secondary Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Button Color :" label-for="input-lg-buttonColor">
            <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" type="color" />
            <b-form-input id="input-lg-buttonColor" v-model="buttonColor" placeholder="Button Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Button Color Second :"
            label-for="input-lg-buttonColorSecond">
            <b-row>
              <b-col md="6">
                <span>Text </span>
                <b-form-input id="input-lg-buttonColorSecondText" v-model="buttonColorSecondText"
                  placeholder="buttonColorSecondText" type="color" />
                <b-form-input id="input-lg-buttonColorSecondText" v-model="buttonColorSecondText"
                  placeholder="buttonColorSecondText" />
              </b-col>
              <b-col md="6">
                <span>Color </span>
                <b-form-input id="input-lg-buttonColorSecondColor" v-model="buttonColorSecondColor"
                  placeholder="buttonColorSecondColor" type="color" />
                <b-form-input id="input-lg-buttonColorSecondColor" v-model="buttonColorSecondColor"
                  placeholder="buttonColorSecondColor" />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Button Color Bottom Navigation Bar :"
            label-for="input-lg-buttonColorSecond">
            <b-row>
              <b-col md="3">
                <span>Text Active </span>
                <b-form-input id="input-lg-buttonColorBottomNavigationBarTextActive"
                  v-model="buttonColorBottomNavigationBarTextActive"
                  placeholder="buttonColorBottomNavigationBarTextActive" type="color" />
                <b-form-input id="input-lg-buttonColorBottomNavigationBarTextActive"
                  v-model="buttonColorBottomNavigationBarTextActive"
                  placeholder="buttonColorBottomNavigationBarTextActive" />
              </b-col>
              <b-col md="3">
                <span>Color Active </span>
                <b-form-input id="input-lg-buttonColorBottomNavigationBarColorActive"
                  v-model="buttonColorBottomNavigationBarColorActive"
                  placeholder="buttonColorBottomNavigationBarColorActive" type="color" />
                <b-form-input id="input-lg-buttonColorBottomNavigationBarColorActive"
                  v-model="buttonColorBottomNavigationBarColorActive"
                  placeholder="buttonColorBottomNavigationBarColorActive" />
              </b-col>
              <b-col md="3">
                <span>Text Inactive </span>
                <b-form-input id="input-lg-buttonColorBottomNavigationBarTextInactive"
                  v-model="buttonColorBottomNavigationBarTextInactive"
                  placeholder="buttonColorBottomNavigationBarTextInactive" type="color" />
                <b-form-input id="input-lg-buttonColorBottomNavigationBarTextInactive"
                  v-model="buttonColorBottomNavigationBarTextInactive"
                  placeholder="buttonColorBottomNavigationBarTextInactive" />
              </b-col>
              <b-col md="3">
                <span>Color Inactive </span>
                <b-form-input id="input-lg-buttonColorBottomNavigationBarColorInactive"
                  v-model="buttonColorBottomNavigationBarColorInactive"
                  placeholder="buttonColorBottomNavigationBarColorInactive" type="color" />
                <b-form-input id="input-lg-buttonColorBottomNavigationBarColorInactive"
                  v-model="buttonColorBottomNavigationBarColorInactive"
                  placeholder="buttonColorBottomNavigationBarColorInactive" />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Button In Product :"
            label-for="input-lg-buttonColorSecond">
            <b-row>
              <b-col md="3">
                <span>Increase </span>
                <b-form-input id="input-lg-buttonIncrease" v-model="buttonIncrease" placeholder="buttonIncrease"
                  type="color" />
                <b-form-input id="input-lg-buttonIncrease" v-model="buttonIncrease" placeholder="buttonIncrease" />
              </b-col>
              <b-col md="3">
                <span>Decrease </span>
                <b-form-input id="input-lg-buttonDecrease" v-model="buttonDecrease" placeholder="buttonDecrease"
                  type="color" />
                <b-form-input id="input-lg-buttonDecrease" v-model="buttonDecrease" placeholder="buttonDecrease" />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Errorbtn Color :" label-for="input-lg-errorbtnColor">
            <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color"
              type="color" />
            <b-form-input id="input-lg-errorbtnColor" v-model="errorbtnColor" placeholder="Errorbtn Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Loading Color :" label-for="input-lg-loadingColor">
            <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color" type="color" />
            <b-form-input id="input-lg-loadingColor" v-model="loadingColor" placeholder="Loading Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Gradient Color :" label-for="input-lg-gradient">
            <b-row>
              <b-col md="4">
                <span> Gradient dark Color </span>
                <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color"
                  type="color" />
                <b-form-input id="input-lg-gradientDark" v-model="gradientDark" placeholder="Gradient dark Color" />
              </b-col>
              <b-col md="4">
                <span> Gradient neutral Color </span>
                <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                  placeholder="Gradient neutral Color" type="color" />
                <b-form-input id="input-lg-gradientNeutral" v-model="gradientNeutral"
                  placeholder="Gradient neutral Color" />
              </b-col>
              <b-col md="4">
                <span> Gradient bright Color </span>
                <b-form-input id="input-lg-gradientBright" v-model="gradientBright" placeholder="Gradient bright Color"
                  type="color" />
                <b-form-input id="input-lg-gradientBright" v-model="gradientBright"
                  placeholder="Gradient bright Color" />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Title Color :" label-for="input-lg-titleColor">
            <b-form-input id="input-lg-titleColor" v-model="titleColor" placeholder="Title Color" type="color" />
            <b-form-input id="input-lg-titleColor" v-model="titleColor" placeholder="Title Color" />
          </b-form-group>
          <b-form-group label-cols="2" label-cols-lg="2" label="Background Color :"
            label-for="input-lg-backgroundColor">
            <b-form-input id="input-lg-backgroundColor" v-model="backgroundColor" placeholder="background Color"
              type="color" />
            <b-form-input id="input-lg-backgroundColor" v-model="backgroundColor" placeholder="background Color" />
          </b-form-group>
          <b-button size="sm" variant="primary" @click="inputChange('color')">
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon icon="SaveIcon" size="12" />
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BFormTags,
  BListGroupItem,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/root/applicationSettings'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'
import debounce from 'debounce'

const STORE_MODULE_NAME = 'applicationSettings'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BBadge,
    draggable,
    BInputGroup,
    BInputGroupAppend,
    BFormTags,
    BListGroupItem,
    BButton,
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // otpSetting
      notification: {
        key_json: {
          key: '',
          updated: null,
        },
      },
      file: {
        android: {
          fcm: {
            name: '',
            url: '',
            updated: null,
          },
        },
        ios: {
          fcm: {
            name: '',
            url: '',
            updated: null,
          },
        },
        huawei: {
          fcm: {
            name: '',
            url: '',
            updated: null,
          },
        },
      },
      otpSetting: {
        type: 'none',
        username: '',
        password: '',
        expireDate: 0,
        smsAmount: 0,
        message: [
          'เพื่อการยืนยันรหัส ป้อนรหัสยืนยัน ',
          '$OTP_CODE',
          ' รหัสอ้างอิง #',
          '$REF',
          ' มีอายุการใช้งาน ',
          '$MINUTE',
          ' นาที',
        ],
      },
      OptionTypeOTP: [
        { name: 'None', value: 'none' },
        { name: 'Firebase', value: 'firebase' },
        { name: 'Thaibulksms', value: 'thaibulksms' },
      ],
      fontFamily: 'K2D',
      OptionFontFamily: [
        { name: 'K2D', value: 'K2D' },
        { name: 'Kanit', value: 'Kanit' },
        { name: 'Nunito', value: 'Nunito' },
        { name: 'Anuphan', value: 'Anuphan' },
      ],
      // forBuildApp
      canUseAppbundle: true,
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    omisePublicKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.publicKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.publicKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    omiseSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.omise.secretKey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'omise.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    autoMessageContactUs: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.autoMessageContactUs
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'autoMessageContactUs',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    notificationServerkey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.notification.serverkey
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'notification.serverkey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    lineKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.line.key
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'line.key',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    primaryColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.primaryColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.primaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    secondaryColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.secondaryColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.secondaryColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorSecondText: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorSecond.buttonText
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorSecond.buttonText',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorSecondColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorSecond.buttonColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorSecond.buttonColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarTextActive: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorBottomNavigationBar.buttonTextActive
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonTextActive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarColorActive: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorBottomNavigationBar.buttonColorActive
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonColorActive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarTextInactive: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorBottomNavigationBar.buttonTextInactive
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonTextInactive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonColorBottomNavigationBarColorInactive: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonColorBottomNavigationBar.buttonColorInactive
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonColorBottomNavigationBar.buttonColorInactive',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonIncrease: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonInProductPage.buttonIncrease
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonInProductPage.buttonIncrease',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    buttonDecrease: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.buttonInProductPage.buttonDecrease
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.buttonInProductPage.buttonDecrease',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    errorbtnColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.errorbtnColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.errorbtnColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    loadingColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.loadingColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.loadingColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientDark: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.dark
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.dark',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientNeutral: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.neutral
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.neutral',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    gradientBright: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.gradient.bright
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.gradient.bright',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    titleColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.titleColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.titleColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    backgroundColor: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.color.backgroundColor
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'color.backgroundColor',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    mappingAPI: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.forBuildApplication.mappingAPI
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'forBuildApplication.mappingAPI',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    appBundle: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.forBuildApplication.appBundle
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'forBuildApplication.appBundle',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    facebookId: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.forBuildApplication.facebookId
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'forBuildApplication.facebookId',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    ads: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.forBuildApplication.ads
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'forBuildApplication.ads',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    // Pay Solution
    paysolutionsSecretKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paysolutions.secretKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paysolutions.secretKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paysolutionsApiKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paysolutions.apiKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paysolutions.apiKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paysolutionsMerchantId: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paysolutions.merchantId
      },
      set(newValue) {
        const obj = {
          name: 'payment.paysolutions.merchantId',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    paysolutionsAuthKey: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.payment.paysolutions.authKey
      },
      set(newValue) {
        const obj = {
          name: 'payment.paysolutions.authKey',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    // Pay Solution
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`, { _id: this.$route.params.id })
      .then(result => {

        if (result.data.data.otp != null) {
          this.otpSetting.type = result.data.data.otp.type
          this.otpSetting.username = result.data.data.otp.username
          this.otpSetting.password = result.data.data.otp.password
          this.otpSetting.message = result.data.data.otp.message
          this.otpSetting.expireDate = result.data.data.otp.expireDate
          this.otpSetting.smsAmount = result.data.data.otp.smsAmount
        }
        if (result.data.data.fontFamily != null) {
          this.fontFamily = result.data.data.fontFamily
        }
        if (result.data.data.file) {
          this.file = result.data.data.file
        }
        if (result.data.data.notification.key_json.key) {
          this.notification = result.data.data.notification
        }
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
    this.typingAppbundle = debounce(this.typingAppbundle, 500)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    typingAppbundle(value) {
      const obj = {
        _id: this.$route.params.id,
        appBundle: value,
      }
      this.canUseAppbundle = true
      store
        .dispatch(`${STORE_MODULE_NAME}/checkAppbundle`, obj)
        .then(result => {
          const { data } = result.data
          this.canUseAppbundle = data

          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        }).catch(error => {
          this.canUseAppbundle = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    readJsonAndUpdate(input, type) {
      // key_json
      console.log(type)
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        // eslint-disable-next-line no-unused-vars
        reader.onload = e => {
          console.log(e)
          // Parse the JSON data
          const jsonData = JSON.parse(e.target.result)
          console.log(jsonData)

          store
            .dispatch(`${STORE_MODULE_NAME}/uploadJson`, { _id: this.$route.params.id, jsonData, type })
            .then(result => {
              if (result.data.data.notification.key_json.key) {
                this.notification = result.data.data.notification
              }
            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })


          this.$refs.refUploadKeyJson.reset()
        }
        reader.readAsText(input.target.files[0])
      }
    },
    inputImageRenderer(input, type) {
      console.log(type)
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        // eslint-disable-next-line no-unused-vars
        reader.onload = e => {
          console.log(e)
          // this.logo = e.target.result
          store
            .dispatch(`${STORE_MODULE_NAME}/uploadFile`, { _id: this.$route.params.id, file: e.target.result, type })
            .then(result => {

              if (result.data.data.file) {
                this.file = result.data.data.file
              }
            })
            .catch(error => {
              console.log('fetchUsers Error : ', error)
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
          this.$refs.refUploadAndroid.reset()
          this.$refs.refUploadiOS.reset()
          this.$refs.refUploadHuawei.reset()
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    inputChange(name) {
      const obj = {
        _id: this.$route.params.id,
      }
      console.log(name)
      if (name === 'color') {
        obj.color = {
          primaryColor: this.primaryColor,
          secondaryColor: this.secondaryColor,
          buttonColor: this.buttonColor,
          buttonColorSecond: {
            buttonText: this.buttonColorSecondText,
            buttonColor: this.buttonColorSecondColor,
          },
          buttonColorBottomNavigationBar: {
            buttonTextActive: this.buttonColorBottomNavigationBarTextActive,
            buttonColorActive: this.buttonColorBottomNavigationBarColorActive,
            buttonTextInactive: this.buttonColorBottomNavigationBarTextInactive,
            buttonColorInactive: this.buttonColorBottomNavigationBarColorInactive,
          },
          buttonInProductPage: {
            buttonIncrease: this.buttonIncrease,
            buttonDecrease: this.buttonDecrease,
          },
          errorbtnColor: this.errorbtnColor,
          loadingColor: this.loadingColor,
          gradient: {
            dark: this.gradientDark,
            neutral: this.gradientNeutral,
            bright: this.gradientBright,
          },
          titleColor: this.titleColor,
          backgroundColor: this.backgroundColor,
        }
      } else if (name === 'otpSetting') {
        obj.otp = this.otpSetting
      } else if (name === 'line') {
        obj.line = {
          key: this.lineKey,
        }
      } else if (name === 'notification') {
        obj.notification = {
          key: this.notificationKey,
          serverkey: this.notificationServerkey,
        }
      } else if (name === 'autoMessageContactUs') {
        obj.autoMessageContactUs = this.autoMessageContactUs
      } else if (name === 'omise') {
        obj.omise = {
          publicKey: this.omisePublicKey,
          secretKey: this.omiseSecretKey,
        }
      } else if (name === 'fontFamily') {
        obj.fontFamily = this.fontFamily
      } else if (name === 'forBuildApplication') {
        obj.forBuildApplication = {
          mappingAPI: this.mappingAPI, // Google Maps
          appBundle: this.appBundle, // Package Name (เช็คซ้ำด้วยนะ)
          facebookId: this.facebookId, // FaceBook ID
          ads: this.ads, // url login / ads
        }
      } else if (name === 'paysolutions') {
        obj.paysolutions = {
          secretKey: this.paysolutionsSecretKey,
          apiKey: this.paysolutionsApiKey,
          merchantId: this.paysolutionsMerchantId,
          authKey: this.paysolutionsAuthKey,
        }
      }

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {

      const obj = {
        _id: this.$route.params.id,
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
